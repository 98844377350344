import Image from 'next/image'
import { useState } from 'react'

export interface VotingButtonProps {
  voted: boolean
  candidateID: number
  voteBtnImg: string
  votedBtnImg: string
  handleVoteCandidate: (candidateID: number) => void
}

const VotingButton = ({ voted, candidateID, voteBtnImg, votedBtnImg, handleVoteCandidate }: VotingButtonProps) => {
  const [disabled, setDisabled] = useState(false)
  return (
    <button
      disabled={disabled}
      className="relative h-button w-full"
      onClick={async () => {
        setDisabled(true)
        await handleVoteCandidate(candidateID)
        setDisabled(false)
      }}
    >
      <Image
        id={`voting-btn-${candidateID}`}
        draggable={false}
        src={voted ? votedBtnImg : voteBtnImg}
        alt=""
        layout="fill"
      />
    </button>
  )
}

export default VotingButton
