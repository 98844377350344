import Image from 'next/image'
import { useTranslation } from 'react-i18next'
import { getImage, transformVoteNumber } from 'shared/utils'

interface ItemVoteCounter {
  vote: number
}

const ItemVoteCounter = ({ vote }) => {
  const { t } = useTranslation()

  return (
    <div
      className="absolute top-0 left-0 flex items-center bg-primary rounded-br-4xl rounded-tl text-white font-medium text-xs large:text-xl py-1.5 large:py-2 pl-1.5 pr-3.5 large:pr-5"
      id="item-vote-counter"
    >
      <div className="relative w-3 h-3 large:w-5 large:h-5 mr-1 large:mr-2">
        <Image draggable={false} layout="fill" src={getImage('heart-shape.png')} alt="" />
      </div>
      {`${transformVoteNumber(vote)} ${t('lượt', { count: vote })}`}
    </div>
  )
}

export default ItemVoteCounter
