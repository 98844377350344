import clsx from 'clsx'
import RemindButton from 'components/RemindButton'
import VotingButton from 'components/VotingButton'
import CandidateVoteCounter from 'components/CandidateVoteCounter'
import { IAxiosBaseQueryError, ICandidate, ISessionInfoCandidate, ITimer, ZoneStateType } from 'types'
import { isIOS, micrositeLogin, micrositeNavigate } from 'shared/webBridge'
import { useEffect, useMemo, useRef } from 'react'
import { DialogType } from 'components/Dialogs/slice'
import { openDialog, requestTimeout, showToast } from 'shared/utils'
import { addToPollingZones, placeVote } from 'services/currentSession'
import Image from 'next/image'
import { RootState, useAppDispatch } from 'store'
import { useRouter } from 'next/router'
import { useGetVoteChanceByCodeQuery } from 'services/campaign'
import { useSelector } from 'react-redux'
import { setIsLogin } from 'domains/Home/slice'
import CarouselArrows from 'components/CarouselArrows'
import { useTranslation } from 'react-i18next'

export interface VotingCandidateListProps {
  zoneId: number
  zoneCode: string
  state: ZoneStateType
  candidates: ICandidate[]
  sessionInfoCandidates: ISessionInfoCandidate[]
  showCandidateVotes: boolean
  remindBtnImg: string
  remindedBtnImg: string
  voteBtnImg: string
  votedBtnImg: string
  submissionTime: { start: number; end: number }
  votedCandidateIDs: number[]
  maxVotes: number
  refetchCurrentSession: () => void
}

const VotingCandidateList = (props: VotingCandidateListProps) => {
  const {
    zoneId,
    zoneCode,
    state,
    candidates,
    sessionInfoCandidates,
    showCandidateVotes,
    remindBtnImg,
    remindedBtnImg,
    voteBtnImg,
    votedBtnImg,
    submissionTime,
    votedCandidateIDs,
    maxVotes,
    refetchCurrentSession,
  } = props

  const dispatch = useAppDispatch()
  const { query, isReady } = useRouter()
  const zoneVoted = useMemo(
    () => votedCandidateIDs.length >= maxVotes || votedCandidateIDs.length >= candidates.length,
    [votedCandidateIDs],
  )
  const isLogin = useSelector((state: RootState) => state.home.isLogin)
  const listRef = useRef(null)
  const { t } = useTranslation()

  const { data, refetch: refetchVoteChance } = useGetVoteChanceByCodeQuery(query.cid as string, {
    skip: !isReady || !isLogin || state !== 'ONGOING',
  })

  const { total_balance: totalBalance } = data || {}

  useEffect(() => {
    let timer: ITimer
    if (state === 'UPCOMING') {
      const toBeOngoingInterval = submissionTime.start - Date.now()
      timer = requestTimeout(() => addToPollingZones(zoneCode), toBeOngoingInterval)
    }

    return () => timer?.clear()
  }, [])

  return (
    <>
      <div
        className={clsx(
          'flex overflow-x-hidden no-scrollbar items-center scroll-smooth list-space pt-3 large:pt-12.5 pb-5.5 large:pb-40 ',
          candidates.length > 2 ? 'justify-between' : 'justify-between',
          { 'overflow-x-scroll': isIOS() },
        )}
        ref={listRef}
        id="voting-candidate-list"
      >
        {candidates.map(
          (
            {
              id: candidateID,
              name: candidateName,
              card_img_url: cardImg,
              remind_title: remindTitle,
              remind_content: remindContent,
            },
            idx,
          ) => {
            const sessionInfo = sessionInfoCandidates[idx] as ISessionInfoCandidate
            const { total_votes: totalCandidateVotes, url } = sessionInfo
            const candidateKey = `${zoneId}${candidateID}`
            const remindButtonProps = {
              candidateKey,
              submissionTime,
              remindBtnImg,
              remindedBtnImg,
              remindTitle,
              remindContent,
            }
            const candidateVoted = votedCandidateIDs.includes(candidateID)
            const handleVoteCandidate = async () => {
              if (!isLogin) {
                //* User have not login
                micrositeLogin(query.site as string, refetchVoteChance)
                return
              }

              if (candidateVoted) {
                openDialog({ zoneId, dialogType: DialogType.VoteSuccessDialog, candidateName, candidateID })
                return
              }

              if (zoneVoted) {
                micrositeNavigate(url)
                return
              }

              if (totalBalance === 0) {
                //* Out of vote
                openDialog({ zoneId, dialogType: DialogType.OutOfVoteDialog })
                return
              }

              const placeVoteRes = await dispatch(
                placeVote({ campaignCode: query.cid as string, zoneCode, candidateID }),
              )

              if (placeVote.fulfilled.match(placeVoteRes)) {
                //* placeVote success
                refetchVoteChance()
                refetchCurrentSession()

                let dialogType = DialogType.VoteSuccessDialog
                const { prize_type: prizeType, prize } = placeVoteRes.payload.ldResult || {}
                if (prizeType === 1) dialogType = DialogType.VoucherRewardDialog
                if (prizeType === 2) dialogType = DialogType.CoinRewardDialog

                openDialog({ zoneId, dialogType, candidateName, quantity: prize?.quantity, candidateID })
              }

              if (placeVote.rejected.match(placeVoteRes)) {
                //* placeVote error
                const { code } = placeVoteRes.payload as IAxiosBaseQueryError['data']
                if (code === 4007) {
                  showToast({
                    zoneId,
                    message: t('Đăng nhập ngay để bình chọn'),
                    status: 'failure',
                  })
                  dispatch(setIsLogin(false))
                } else {
                  showToast({
                    zoneId,
                    message: `${t('Ui! Có lỗi xảy ra mất rồi.')} ${t('Bạn vui lòng thử lại nha!')}`,
                    status: 'failure',
                  })
                }
              }
            }

            const votingButtonProps = {
              voted: candidateVoted,
              candidateID,
              voteBtnImg,
              votedBtnImg,
              handleVoteCandidate,
            }

            return (
              <div
                className={clsx('w-card h-card relative select-none mx-2 large:mx-3', {
                  'opacity-50': !candidateVoted && zoneVoted,
                })}
                id={`candidate-${candidateID}`}
                key={candidateID}
              >
                <div className="relative h-candidate" aria-hidden onClick={() => micrositeNavigate(url)}>
                  <Image draggable={false} src={cardImg} alt="" layout="fill" />
                </div>
                {state === 'ONGOING' ? (
                  <VotingButton {...votingButtonProps} />
                ) : (
                  <RemindButton {...remindButtonProps} />
                )}
                {showCandidateVotes && (candidateVoted || zoneVoted) && (
                  <CandidateVoteCounter vote={totalCandidateVotes} />
                )}
              </div>
            )
          },
        )}
      </div>
      {!isIOS() && <CarouselArrows listRef={listRef} listLength={candidates.length} />}
    </>
  )
}

export default VotingCandidateList
