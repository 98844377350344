import { ReactNode, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { closeDialog, requestTimeout } from 'shared/utils'

interface GeneralDialogProps {
  id?: string
  zoneId: number
  open: boolean
  children?: ReactNode
  primaryActionText: string
  secondaryActionText?: string
  onClickPrimary: () => void
  onClickSecondary?: () => void
  disableSecondary?: boolean
}

const GeneralDialog = (props: GeneralDialogProps) => {
  const {
    id,
    zoneId,
    open,
    children,
    primaryActionText,
    secondaryActionText,
    onClickPrimary,
    onClickSecondary,
    disableSecondary,
  } = props
  const [visible, setVisible] = useState(open)
  const { t } = useTranslation()

  useEffect(() => {
    // delay 200ms for hideout transition to work
    if (!open) requestTimeout(() => setVisible(false), 200)
    else setVisible(true)
  }, [open])

  const handleSecondaryClick = () => {
    if (onClickSecondary) onClickSecondary()
    else closeDialog({ zoneId })
  }

  return (
    <div
      className={`absolute top-0 left-0 w-full h-full bg-black bg-opacity-70 flex items-center justify-center transition-opacity duration-200 ${
        open ? 'opacity-100' : 'opacity-0'
      } ${visible ? 'visible' : 'invisible'}`}
      id={id}
    >
      <div className="bg-white rounded w-5/6 large:w-auto" id="dialog-card">
        <div
          className="flex items-center flex-col p-4 large:p-10 text-sm leading-6 text-center large:text-xl large:leading-8"
          id="content"
        >
          {children}
        </div>
        <div className="flex flex-row-reverse border-t text-sm" id="actions">
          <button className="flex-1 py-4 text-highlight large:text-xl" id="primary-action" onClick={onClickPrimary}>
            {primaryActionText}
          </button>
          {!disableSecondary && (
            <>
              <div className="w-px bg-gray-200" id="divider" />
              <button className="flex-1 py-4 large:text-xl" id="secondary-action" onClick={handleSecondaryClick}>
                {secondaryActionText || t('Đóng')}
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

GeneralDialog.defaultProps = {
  id: 'general-dialog',
  open: false,
  disableSecondary: false,
}

export default GeneralDialog
