import ZoneVoteCounter from 'components/ZoneVoteCounter'
import Image from 'next/image'

interface ZoneHeaderProps {
  headerImg: string
  totalVotes: number
  showTotalVotes: boolean
}

const ZoneHeader = ({ headerImg, totalVotes, showTotalVotes }: ZoneHeaderProps) => {
  return (
    <div className="relative mb-1.5 large:mb-2.5" id="zone-header">
      <div className="h-header-img w-header-img relative">
        <Image draggable={false} src={headerImg} alt="" layout="fill" />
      </div>
      {showTotalVotes && <ZoneVoteCounter vote={totalVotes} />}
    </div>
  )
}

export default ZoneHeader
