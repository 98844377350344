import { getImage, isLargeScreen } from 'shared/utils'
import Image from 'next/image'
import clsx from 'clsx'
import React, { useEffect, useState } from 'react'

const CarouselArrows = ({ listRef, listLength }) => {
  const [currentPostion, setCurrentPostion] = useState(0)
  const CARD_WIDTH_WITH_MARGIN = isLargeScreen() ? 479 : 176
  const cardFullOnScreen = Math.floor(window.innerWidth / CARD_WIDTH_WITH_MARGIN)

  useEffect(() => {
    listRef.current.scrollTo({ left: currentPostion * CARD_WIDTH_WITH_MARGIN })
  }, [currentPostion])

  const handlePrevClick = () => {
    setCurrentPostion(Math.max(currentPostion - 1, 0))
  }

  const handleNextClick = () => {
    setCurrentPostion(Math.min(currentPostion + 1, listLength - 1))
  }

  return (
    <>
      <div
        aria-hidden
        className={clsx('carousel-arrow left-1 rotate-180', { invisible: currentPostion === 0 })}
        onClick={handlePrevClick}
      >
        <Image src={getImage('arrow.png')} alt="<" height={18} width={18} layout="fixed" />
      </div>
      <div
        aria-hidden
        className={clsx('carousel-arrow right-1', { invisible: listLength - currentPostion <= cardFullOnScreen })}
        onClick={handleNextClick}
      >
        <Image src={getImage('arrow.png')} alt=">" height={18} width={18} layout="fixed" />
      </div>
    </>
  )
}

export default React.memo(CarouselArrows)
