import Image from 'next/image'
import { useRouter } from 'next/router'
import { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { getImage } from 'shared/utils'
import { RootState } from 'store'

const FullscreenError = ({ statusCode = 0 }: { statusCode?: number }) => {
  const error = useSelector((state: RootState) => state.home.error)
  const router = useRouter()
  const { t } = useTranslation()

  const { data, status } = error || {}
  const errorCode = data?.code || status || statusCode

  const handleRetry = () => {
    router.reload()
  }

  const content = useMemo(() => {
    if (errorCode === 3021) return <p>{t('Sự kiện này không tồn tại.')}</p>
    if (errorCode === 404) return <p>{t('Không thể tìm thấy trang bạn muốn truy cập.')}</p>

    return (
      <>
        <div className="mb-2 large:mb-5">
          <p>{t('Ui! Có lỗi xảy ra mất rồi ({{errorCode}}).', { errorCode })}</p>
          <p>{t('Bạn vui lòng thử lại nha!')}</p>
        </div>
        <button className="text-highlight large:text-xl py-1 px-5" onClick={handleRetry} type="button">
          {t('Thử lại')}
        </button>
      </>
    )
  }, [errorCode])

  if (!errorCode) return null

  return (
    <div className="text-center absolute top-0 left-0 bg-white w-full h-screen text-sm leading-relaxed large:text-xl">
      <div className="relative mb-4 large:mb-10 mt-28 mx-auto w-28 h-28 large:w-52 large:h-52">
        <Image src={getImage('crying-shrimp.png')} alt="" layout="fill" draggable={false} />
      </div>
      {content}
    </div>
  )
}

export default FullscreenError
