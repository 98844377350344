import Image from 'next/image'
import { Trans, useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { closeDialog, getImage, openDialog, requestTimeout } from 'shared/utils'
import { RootState } from 'store'
import GeneralDialog from '../GeneralDialog'
import { DialogType } from '../slice'

interface VoucherRewardDialogProps {
  zoneId: number
}

const VoucherRewardDialog = ({ zoneId }: VoucherRewardDialogProps) => {
  const open = useSelector((state: RootState) => state.dialogs[zoneId]?.type) === DialogType.VoucherRewardDialog
  const candidateName = useSelector((state: RootState) => state.dialogs[zoneId]?.candidateName)
  const candidateID = useSelector((state: RootState) => state.dialogs[zoneId]?.candidateID)
  const { t } = useTranslation()

  const handleCloseDialog = () => {
    closeDialog({ zoneId })
    //* delay 200ms for hideout transition to work
    requestTimeout(
      () => openDialog({ zoneId, dialogType: DialogType.VoteSuccessDialog, candidateName, candidateID }),
      200,
    )
  }

  return (
    <GeneralDialog
      open={open}
      id="voucher-reward-dialog"
      zoneId={zoneId}
      disableSecondary
      primaryActionText={t('Đóng')}
      onClickPrimary={handleCloseDialog}
    >
      <div className="relative mb-3 large:mb-6 w-24 h-24 large:w-40 large:h-40">
        <Image draggable={false} layout="fill" src={getImage('voucher-shrimp.png')} alt="" />
      </div>
      <b className="mb-1">{t('Chúc mừng!')}</b>
      <p>
        <Trans>
          Bạn vừa nhận được <b>Voucher</b> may mắn!
        </Trans>
      </p>
      <p>{t('Hãy kiểm tra thông báo hoặc ví voucher nhé!')}</p>
    </GeneralDialog>
  )
}

export default VoucherRewardDialog
