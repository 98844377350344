import Image from 'next/image'
import { useTranslation } from 'react-i18next'
import { getImage, transformVoteNumber } from 'shared/utils'

interface ZoneVoteCounterProps {
  vote: number
}

const ZoneVoteCounter = ({ vote }: ZoneVoteCounterProps) => {
  const { t } = useTranslation()

  return (
    <div className="absolute font-shopee flex items-center pr-4 top-1/2 -translate-y-1/2 right-0 large:text-2xl">
      <div className="flex relative w-4 h-4 large:w-6 large:h-6">
        <Image src={getImage('heart-icon.png')} alt="" layout="fill" draggable={false} />
      </div>
      &nbsp;
      <p className="font-bold">{transformVoteNumber(vote)}</p>
      &nbsp;
      <p>{t('lượt', { count: vote })}</p>
    </div>
  )
}

export default ZoneVoteCounter
