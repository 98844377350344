import { addReminder } from 'domains/Home/slice'
import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'
import { micrositeReminder } from 'shared/webBridge'
import { getMicrositeUniversalUrl, simpleHash } from 'shared/utils'
import { RootState, useAppDispatch } from 'store'
import Image from 'next/image'

export interface RemindButtonProps {
  candidateKey: string
  submissionTime: { start: number; end: number }
  remindBtnImg: string
  remindedBtnImg: string
  remindTitle: string
  remindContent: string
}

const RemindButton = (props: RemindButtonProps) => {
  const { candidateKey, submissionTime, remindBtnImg, remindedBtnImg, remindTitle, remindContent } = props
  const { query } = useRouter()
  const dispatch = useAppDispatch()
  const reminderList = useSelector((state: RootState) => state.home.reminderList)
  const { start, end } = submissionTime

  const reminderData = {
    startTime: Math.round(+start / 1000),
    endTime: Math.round(+end / 1000),
    reminderId: +candidateKey,
    redirectPath: getMicrositeUniversalUrl(query.site as string),
    title: remindTitle,
    content: remindContent,
    notes: remindContent,
  }

  const reminderDataHash = simpleHash(JSON.stringify(reminderData))
  const isReminded = reminderList.map((reminder) => reminder.hash).includes(reminderDataHash)

  const reminderHandler = (ev: React.MouseEvent<HTMLElement>) => {
    ev.stopPropagation()
    // disabled when the candidate is already reminded
    if (isReminded) return

    micrositeReminder(reminderData).then(() => {
      dispatch(addReminder({ startTime: start, hash: reminderDataHash }))
    })
  }

  return (
    <div className="relative h-button" aria-hidden onClick={reminderHandler} id={`reminder-btn-${candidateKey}`}>
      <Image draggable={false} src={isReminded ? remindedBtnImg : remindBtnImg} alt="" layout="fill" />
    </div>
  )
}

export default RemindButton
