import Image from 'next/image'
import { useRouter } from 'next/router'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { campaignApi } from 'services/campaign'
import { closeDialog, getImage, getSharePageLink, getUnifiedLink } from 'shared/utils'
import { micrositeNavigate } from 'shared/webBridge'
import { RootState } from 'store'
import GeneralDialog from '../GeneralDialog'
import { DialogType } from '../slice'

interface OutOfVoteDialogProps {
  zoneId: number
}

const OutOfVoteDialog = ({ zoneId }: OutOfVoteDialogProps) => {
  const open = useSelector((state: RootState) => state.dialogs[zoneId]?.type) === DialogType.OutOfVoteDialog
  const { query } = useRouter()
  const { data } = campaignApi.endpoints.getVoteChanceByCode.useQueryState(query.cid as string)
  const { remaining_share_times: remainingShareTimes } = data || {}
  const outOfShareTimes = remainingShareTimes === 0
  const { t } = useTranslation()
  const handleAction = () => {
    closeDialog({ zoneId })
    if (outOfShareTimes) return

    //* Share times available so we navigate to Share page
    micrositeNavigate(getUnifiedLink(getSharePageLink('campaign')))
  }

  return (
    <GeneralDialog
      id="out-of-vote-dialog"
      zoneId={zoneId}
      open={open}
      primaryActionText={!outOfShareTimes ? t('Chia sẻ ngay') : t('Đóng')}
      onClickPrimary={handleAction}
      disableSecondary={outOfShareTimes}
    >
      <div className="relative mb-3 large:mb-6 w-20 h-20 large:w-36 large:h-36">
        <Image draggable={false} layout="fill" src={getImage('crying-shrimp.png')} alt="" />
      </div>

      <p>{t('Bạn đã hết lượt bình chọn mất rồi.')}</p>
      {!outOfShareTimes && <p>{t('Chia sẻ ngay để nhận thêm lượt nhé!')}</p>}
    </GeneralDialog>
  )
}

export default OutOfVoteDialog
