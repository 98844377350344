import Image from 'next/image'
import { useTranslation } from 'react-i18next'
import { useSelector } from 'react-redux'
import { closeDialog, getImage, getSharePageLink, getUnifiedLink, showToast } from 'shared/utils'
import { isPC, micrositeNavigate } from 'shared/webBridge'
import { RootState } from 'store'
import GeneralDialog from '../GeneralDialog'
import { DialogType } from '../slice'

interface VoteSuccessDialogProps {
  zoneId: number
  zoneCode: string
}

const VoteSuccessDialog = ({ zoneId, zoneCode }: VoteSuccessDialogProps) => {
  const open = useSelector((state: RootState) => state.dialogs[zoneId]?.type) === DialogType.VoteSuccessDialog
  const candidateName = useSelector((state: RootState) => state.dialogs[zoneId]?.candidateName)
  const candidateID = useSelector((state: RootState) => state.dialogs[zoneId]?.candidateID)
  const { t } = useTranslation()

  const handleShareNow = () => {
    closeDialog({ zoneId })

    if (!isPC()) {
      micrositeNavigate(getUnifiedLink(getSharePageLink('votedItem', zoneCode, candidateID)))
    } else {
      showToast({ zoneId, message: t('Tính năng này không được hỗ trợ trên thiết bị của bạn'), status: 'failure' })
    }
  }

  return (
    <GeneralDialog
      id="vote-success-modal"
      zoneId={zoneId}
      open={open}
      primaryActionText={t('Khoe ngay')}
      onClickPrimary={handleShareNow}
    >
      <div className="relative mb-3 large:mb-6 w-20 h-20 large:w-36 large:h-36">
        <Image draggable={false} layout="fill" src={getImage('success-shrimp.png')} alt="" />
      </div>
      <p>{t('Bạn đã bình chọn cho sản phẩm:')}</p>
      <p className="font-medium uppercase">{candidateName}</p>
      <p>{t('Cùng chờ kết quả qua thông báo nhé!')}</p>
    </GeneralDialog>
  )
}

export default VoteSuccessDialog
