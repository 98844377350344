import Home from 'domains/Home'
import Head from 'next/head'
import { useTranslation } from 'react-i18next'

const HomePage = () => {
  const { t } = useTranslation()

  return (
    <>
      <Head>
        <title>{t('Voting tool')}</title>
        <meta name="description" content="Voting tool home page" />
      </Head>
      <Home />
    </>
  )
}

export default HomePage
