import CandidateVoteCounter from 'components/CandidateVoteCounter'
import Image from 'next/image'
import { micrositeNavigate } from 'shared/webBridge'
import { ICandidate, ISessionInfoCandidate } from 'types'
import { useTranslation } from 'react-i18next'

export interface WinningCandidateProps {
  zoneId: number
  winningBackgroundUrl: string
  winningMsg: string
  nonWinningMsg: string
  votedCandidateIDs: number[]
  winningCandidate: ICandidate
  showCandidateVotes: boolean
  sessionInfo: ISessionInfoCandidate
}

const WinningCandidate = ({
  zoneId,
  winningBackgroundUrl,
  winningMsg,
  nonWinningMsg,
  votedCandidateIDs,
  winningCandidate,
  showCandidateVotes,
  sessionInfo,
}: WinningCandidateProps) => {
  const { t } = useTranslation()
  const winnginCandidateCardImg = winningCandidate['card_img_url']
  let message: string

  if (votedCandidateIDs.length === 0) message = winningCandidate['default_msg']
  else if (votedCandidateIDs.includes(winningCandidate.id)) message = winningMsg
  else message = nonWinningMsg

  const { url, total_votes: totalCandidateVotes } = sessionInfo

  const handleRedirect = () => {
    micrositeNavigate(url)
  }

  return (
    <div id={`winning-candidate-${zoneId}`} className="my-2 w-full h-zone relative">
      <Image draggable={false} src={winningBackgroundUrl} alt="" layout="fill" />
      <div className="absolute top-0 left-0 flex items-center justify-between h-full w-full px-4 smx:px-2 large:px-20 ">
        <div id="winning-candidate-card" className="w-card h-card relative" onClick={handleRedirect}>
          <div className="relative h-candidate">
            <Image draggable={false} src={winnginCandidateCardImg} alt="" layout="fill" />
          </div>
          <button className="flex items-center justify-center text-white bg-primary w-full h-button rounded-b large:rounded-b-lg font-shopee text-sm large:text-2xl">
            {t('Sản phẩm yêu thích')}
          </button>
          {showCandidateVotes && <CandidateVoteCounter vote={totalCandidateVotes} />}
        </div>
        <p className="text-white font-shopee w-1/2 leading-5 ml-6 large:text-4xl">{message}</p>
      </div>
    </div>
  )
}

export default WinningCandidate
