import Image from 'next/image'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { getImage, requestTimeout } from 'shared/utils'
import { RootState } from 'store'

interface ToastProps {
  zoneId: number
}

const Toast = ({ zoneId }: ToastProps) => {
  const { isShow, message, status } = useSelector((state: RootState) => state.toast[zoneId]) || {
    isShow: false,
    message: '',
    status: 'success',
  }

  const [visible, setVisible] = useState(isShow)

  useEffect(() => {
    // delay 200ms for hideout transition to work
    if (!isShow) requestTimeout(() => setVisible(false), 200)
    else setVisible(true)
  }, [isShow])

  return (
    <div
      className={`absolute bg-black bg-opacity-80 top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 rounded large:rounded-lg p-3 large:p-5 min-w-1/2 min-w-60 flex flex-col items-center transition-opacity duration-200 ${
        isShow ? 'opacity-100' : 'opacity-0'
      } ${visible ? 'visible' : 'invisible'}`}
      id={`toast-${zoneId}`}
    >
      <div className="relative mb-1 large:mb-3 w-8 h-9 large:w-14 large:h-16">
        <Image draggable={false} layout="fill" className="mb-3" src={getImage(`${status}-icon.svg`)} alt={status} />
      </div>
      <p className="text-white text-sm text-center large:text-xl">{message}</p>
    </div>
  )
}

export default Toast
