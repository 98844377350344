import FullscreenError from 'components/FullscreenError'
import VotePanel from 'components/VotePanel'
import VotingZone from 'components/VotingZone'
import { useEffect } from 'react'
import { getPersistentReminderList } from 'shared/persistent'
import { useAppDispatch } from 'store'
import { setReminderList } from './slice'

const Home = () => {
  const dispatch = useAppDispatch()

  useEffect(() => {
    const persistentReminderList = getPersistentReminderList()
    dispatch(setReminderList(persistentReminderList))
  }, [])

  return (
    <>
      <VotePanel />
      <VotingZone />
      <FullscreenError />
    </>
  )
}

export default Home
