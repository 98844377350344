import Toast from 'components/Toast'
import ZoneHeader from 'components/ZoneHeader'
import { useRouter } from 'next/dist/client/router'
import { useGetCampaignByCodeQuery } from 'services/campaign'
import Dialogs from 'components/Dialogs'
import VotingCandidateList, { VotingCandidateListProps } from 'components/VotingCandidateList'
import WinningCandidate, { WinningCandidateProps } from 'components/WinningCandiate'
import { useGetCurrentSession } from 'services/currentSession'
import { ICandidate, ICurrentSession, IZone } from 'types'
import { useEffect } from 'react'
import { useAppDispatch } from 'store'
import { setError } from 'domains/Home/slice'

const VotingZone = () => {
  const { query, isReady } = useRouter()
  const dispatch = useAppDispatch()
  const {
    data: campaignData,
    isLoading: isCampaignLoading,
    error: campaignError,
  } = useGetCampaignByCodeQuery(query.cid as string, { skip: !isReady })
  const {
    data: currentSessionData,
    isLoading: isCurrentSessionLoading,
    refetch,
    error: currentSessionError,
  } = useGetCurrentSession(query.cid as string, { skip: !isReady })

  useEffect(() => {
    if (campaignError) dispatch(setError(campaignError))
  }, [campaignError])

  useEffect(() => {
    if (currentSessionError) dispatch(setError(currentSessionError))
  }, [currentSessionError])

  if (isCampaignLoading || isCurrentSessionLoading) return null

  const zones = campaignData?.zones as IZone[]

  return (
    <>
      {zones?.map(
        (
          {
            code: zoneCode,
            candidates,
            header_img_url: headerImg,
            max_votes: maxVotes,
            show_candidate_votes: showCandidateVotes,
            remind_button_url: remindBtnImg,
            reminded_button_url: remindedBtnImg,
            vote_button_url: voteBtnImg,
            voted_button_url: votedBtnImg,
            submission_time: submissionTime,
            winning_background_url: winningBackgroundUrl,
            winning_msg: winningMsg,
            nonwinning_msg: nonWinningMsg,
          },
          zoneId: number,
        ) => {
          const defaultCurrentSession: ICurrentSession = {
            id: 0,
            code: '',
            state: 'UPCOMING',
            total_votes: 0,
            voted_candidate_ids: [],
            candidates: [],
          }
          const currentSession = currentSessionData?.zones[zoneId] || defaultCurrentSession

          const { state, total_votes: totalVotes, candidates: sessionInfoCandidates } = currentSession
          const votedCandidateIDs = currentSession['voted_candidate_ids'] || []

          const votingCandidateListProps: VotingCandidateListProps = {
            zoneId,
            zoneCode,
            state,
            candidates,
            sessionInfoCandidates,
            showCandidateVotes,
            remindBtnImg,
            remindedBtnImg,
            voteBtnImg,
            votedBtnImg,
            submissionTime,
            votedCandidateIDs,
            maxVotes,
            refetchCurrentSession: refetch,
          }
          const winningCandidateProps: WinningCandidateProps = {
            zoneId,
            winningBackgroundUrl,
            winningMsg,
            nonWinningMsg,
            votedCandidateIDs,
            winningCandidate: candidates.find(
              (candidate) => candidate.id === sessionInfoCandidates[0].id,
            ) as ICandidate,
            showCandidateVotes,
            sessionInfo: sessionInfoCandidates[0],
          }
          return (
            <div key={zoneId} className="mb-2.5 large:mb-5 relative" id={`zone-${zoneId}`}>
              <ZoneHeader headerImg={headerImg} totalVotes={totalVotes} showTotalVotes={state !== 'UPCOMING'} />
              {state === 'ENDED' ? (
                <WinningCandidate {...winningCandidateProps} />
              ) : (
                <VotingCandidateList {...votingCandidateListProps} />
              )}
              <Toast zoneId={zoneId} />
              <Dialogs zoneId={zoneId} zoneCode={zoneCode} />
            </div>
          )
        },
      )}
    </>
  )
}

export default VotingZone
