import CoinRewardDialog from './CoinRewardDialog'
import OutOfVoteDialog from './OutOfVoteDialog'
import VoteSuccessDialog from './VoteSuccessDialog'
import VoucherRewardDialog from './VoucherRewardDialog'

interface DialogsProps {
  zoneId: number
  zoneCode: string
}

const Dialogs = ({ zoneId, zoneCode }: DialogsProps) => {
  return (
    <>
      <VoteSuccessDialog zoneId={zoneId} zoneCode={zoneCode} />
      <OutOfVoteDialog zoneId={zoneId} />
      <CoinRewardDialog zoneId={zoneId} />
      <VoucherRewardDialog zoneId={zoneId} />
    </>
  )
}

export default Dialogs
